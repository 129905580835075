import React from 'react';
import loadable from '@loadable/component';

import { Route, Routes, BrowserRouter } from 'react-router-dom';

const Footer = loadable(() => import('./components/Molecules/Footer'));
const Navbar = loadable(() => import('./components/Molecules/Navbar'));
const Homepage = loadable(() => import('./components/Pages/Homepage'));
const Contact = loadable(() => import('./components/Pages/ContactUs'));
const About = loadable(() => import('./components/Pages/AboutUs'));
const Business = loadable(() => import('./components/Pages/Business'));
const ProductsLanding = loadable(() =>
  import('./components/Pages/ProductsLanding')
);
const ComingSoon = loadable(() => import('./components/Pages/ComingSoon'));
const MobileApp = loadable(() => import('./components/Pages/MobileApp'));
const Error404 = loadable(() => import('./components/Pages/Error404'));
const FAQ = loadable(() => import('./components/Pages/Faq'));
const CMS = loadable(() => import('./components/Pages/CMS'));
const BecomeDistributor = loadable(() =>
  import('./components/Pages/BecomeDistributor')
);
const HostCharger = loadable(() => import('./components/Pages/hostCharger'));

const ViewWithNavbar = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="min-h-screen">{children}</div>
      <Footer />
    </>
  );
};

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<ViewWithNavbar children={<Homepage />} />}
          />
          <Route
            path="/get-in-touch"
            element={<ViewWithNavbar children={<Contact />} />}
          />
          <Route
            path="/about"
            element={<ViewWithNavbar children={<About />} />}
          />
          <Route
            path="/business"
            element={<ViewWithNavbar children={<Business />} />}
          />
          <Route
            path="/hardware"
            element={<ViewWithNavbar children={<ProductsLanding />} />}
          />
          <Route
            path="/software/cms"
            element={<ViewWithNavbar children={<CMS />} />}
          />
          <Route
            path="/software/mobile-app"
            element={<ViewWithNavbar children={<MobileApp />} />}
          />
          <Route
            path="/partner-with-us"
            element={<ViewWithNavbar children={<BecomeDistributor />} />}
          />
          <Route
            path="/host-charger"
            element={<ViewWithNavbar children={<HostCharger />} />}
          />
          <Route path="/faq" element={<ViewWithNavbar children={<FAQ />} />} />

          <Route
            path="/coming-soon"
            element={<ViewWithNavbar children={<ComingSoon />} />}
          />
          <Route
            path="*"
            element={<ViewWithNavbar children={<Error404 />} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
